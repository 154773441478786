import React, {Component} from 'react';
import PublicPage from "./PublicPage";
import Content from "./Content";
import Panel from "./Panel";
import PropTypes from "prop-types";
import Row from "./Row";
import Col from "./Col";
import {logError} from "../ajax";
import {AppContext} from "../../context/global";

class PageErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        logError(this.context.user, error);
    }

    render() {
        if (this.state.hasError) {
            return (
                <PublicPage title={this.props.title}>
                    <Content>
                        <Row>
                            <Col md={8} mdOffset={2}>
                                <Panel
                                    title={
                                        <span className="text-danger">
                                            <i className="icon mdi mdi-alert-triangle"/>
                                            &nbsp;
                                            Ops! O sistema apresentou um erro inesperado
                                        </span>
                                    }
                                    borderColor="danger">
                                    <h3>Nossos engenheiros já foram notificados e o problema será resolvido brevemente.</h3>
                                </Panel>
                            </Col>
                        </Row>
                    </Content>
                </PublicPage>
            );
        }
        return this.props.children;
    }
}

PageErrorBoundary.propTypes = {
    title: PropTypes.string,
};

PageErrorBoundary.defaultProps = {
    title: 'Erro inesperado',
};

PageErrorBoundary.contextType = AppContext;

export default PageErrorBoundary;